import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  new Swiper('#js-theater-card-swiper', {
    wrapperClass: 'p-theater-card-carousel__wrapper',
    slideClass: 'p-theater-card',
    navigation: {
      nextEl: '.p-theater-card-carousel__next',
      prevEl: '.p-theater-card-carousel__prev'
    },
    slidesPerView: 'auto',
    loop: false,
    allowTouchMove: false,
    spaceBetween: 10,
    slidesPerGroup: 9
  });
});
